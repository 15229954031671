import Gen from '@helper/Gen';
import BOGen from './helper/BOGen';

var bo_router = [{
        path: "dashboard",
        name: "BoDashboard",
        component: () =>
            import ("@backend/Dashboard.vue")
    },
    {
        path: "profile/:id?",
        name: "BoProfile",
        component: () =>
            import ("@backend/Profile.vue")
    },
    {
        path: "home/:id?",
        name: "Home",
        component: () =>
            import("@backend/Home.vue")
    },
    {
        path: "about/:id?",
        name: "About",
        component: () =>
            import("@backend/About.vue")
    },
    {
        path: "blog/:id?",
        name: "Blog",
        component: () =>
            import("@backend/Blog.vue")
    },
    {
        path: "contact/:id?",
        name: "Contact",
        component: () =>
            import("@backend/Contact.vue")
    },
    {
        path: "testimoni/:id?",
        name: "Testimoni",
        component: () =>
            import("@backend/Testimoni.vue")
    },
    {
        path: "seo/:id?",
        name: "SettingSeo",
        component: () =>
            import("@backend/SettingSeo.vue")
    },
    {
        path: "legal-page/:id?",
        name: "SettingLegalPage",
        component: () =>
            import("@backend/SettingLegalPage.vue")
    },
    {
        path: "static-content/:id?",
        name: "StaticContent",
        component: () =>
            import("@backend/StaticContent.vue")
    },
    {
        path: "users/:id?",
        name: "BoUsers",
        component: () =>
            import ("@backend/Users.vue")
    },
    {
        path: "member/:id?",
        name: "BoMember",
        component: () =>
            import ("@backend/MemberProfile.vue")
    },
    {
        path: "banner/:id?",
        name: "Banner",
        component: () =>
            import ("@backend/Banner.vue")
    },
    {
        path: "user-level/:id?",
        name: "BoUserLevel",
        component: () =>
            import ("@backend/UserLevel.vue")
    },
    {
        path: "product/:id?",
        name: "Product",
        component: () =>
            import ("@backend/Product.vue")
    },
    {
        path: "member-product/:id?",
        name: "MemberProduct",
        component: () =>
            import ("@backend/MemberProduct.vue")
    },
    {
        path: "monthly-fee/:id?",
        name: "MonthlyFee",
        component: () =>
            import ("@backend/MonthlyFee.vue")
    },
    {
        path: "product-category/:id?",
        name: "ProductCategory",
        component: () =>
            import ("@backend/ProductCategory.vue")
    },
    {
        path: "customers/:id?",
        name: "BoCustomers",
        component: () =>
            import ("@backend/BoCustomers.vue")
    },
    {
        path: "brand/:id?",
        name: "Brand",
        component: () =>
            import ("@backend/Partner.vue")
    },
    {
        path: "bank/:id?",
        name: "MrBank",
        component: () =>
            import ("@backend/MrBank.vue")
    },
    {
        path: "bank-artisan/:id?",
        name: "ArtisanBank",
        component: () =>
            import ("@backend/ArtisanBank.vue")
    },
    {
        path: "transaction/:id?",
        name: "Transaction",
        component: () =>
            import ("@backend/Transaction.vue")
    },
    {
        path: "received/:id?",
        name: "Received",
        component: () =>
            import ("@backend/Received.vue")
    },
    { path: '*', redirect: { name: "BoDashboard" } },
];

var menuBypassRole = ["BoProfile"]

bo_router.map((v) => {
    v.beforeEnter = (to, from, next) => {
        var user = BOGen.user()
        if (!user || !Gen.getStorage('botk')) return next({ name: "BoLogin" })
        if (menuBypassRole.indexOf(to.name) > -1) return next()
        if (user.bu_level == 0) return next()
        if (user.menuRole.indexOf(to.name) == -1) return next({ name: user.menuRole[0] })
        next()
    }
    v.meta = { bo_protected: true }
})

var data = [{
        name: "BoLogin",
        path: "login",
        component: () =>
            import ("@backend/Login.vue"),
        beforeEnter: (to, from, next) => {
            var user = BOGen.user()
            if (user && Gen.getStorage('botk')) return next({ name: "BoDashboard" })
            next()
        }
    },
    // FileManager
    {
        name: "FileManager",
        path: 'file-explore',
        component: () =>
            import ("@components/FileManagerPage.vue")
    },
    {
        path: "",
        component: () =>
            import ('@/layout/BoLayout.vue'),
        redirect: { name: 'BoDashboard' },
        children: bo_router
    },
]

export default data